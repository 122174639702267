import "./style.css";
import validator from 'validator';
import eye from "../../../assets/images/eye.svg"
import eyeclose from "../../../assets/images/eye-slash.svg"
import {
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../context/AuthContext";
import Button from "../../../components/Button/index";
import { GrClose } from "react-icons/gr";

interface ValueStateProps {
  email: string;
  password: string;
}
export default function Index() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [values, setValues] = React.useState<ValueStateProps>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = useState<any>(null);

  const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);

  const onInputChange = (key: "email" | "password") => (value: string) => {
    setValues({ ...values, [key]: value });
  };

  const validatePassword = () => {
    return values?.password?.length >= 8;
  };

  const validateEmail = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(values?.email);
  };

  const validate = () => {
    return validateEmail() && validatePassword();
  };

  useEffect(() => {
    setIsBtnDisabled(!validate() || isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isLoading]);

  const handleSubmit = async () => {
    setIsLoading(true);
    await auth
      .signIn({ username: values?.email, password: values?.password })
      .then((e) => {
        setIsLoading(false);
        if (e?.res !== 200) {
          setError(e?.res?.message);
           navigate("/dashboard")
        }
        console.debug("logged in > ", e);
      })
      .catch((e) => {
        console.debug("logged in error > ", e);
        setError(e)
        setIsLoading(false);
      });
  };

  // const [errorMessage, setErrorMessage] = useState('')
  
  // const validater = (value) => {
  
  //   if (validator.isStrongPassword(value, {
  //     minLength: 8, minLowercase: 1,
  //     minUppercase: 1, minNumbers: 1, minSymbols: 1
  //   })) {
  //     setErrorMessage('Is Strong Password')
  //   } else {
  //     setErrorMessage('Is Not Strong Password')
  //   }
  // }
  const [show, setShow] = useState(false)
  return (
    <div className="container-login d-flex justify-content-center align-items-center">
      <div className="login-cube"></div>
      <div className="login-arrow"></div>
      <div className="login-container p-3 ">
        <div
          onClick={() => navigate("/signup")}
          className="c-p text-muted d-flex justify-content-center"
        >
          Not a member ?
          <Link
            to="/signup"
            style={{ textDecoration: "none", color: "#4B54E5" }}
          >
            <div className="cpsignup"> Signup Today</div>
          </Link>
          
        </div>
        <div className="home" style={{width:"10px"}}>
                <Link to="/">
                    <GrClose/>
                   </Link>
            
      </div>
       
        
        <div
          className="d-flex login-heading title fs-20 f-b justify-content-center  "
          style={{ color: "#8A8A8A" }}
        >
          Login
        </div>

        <div className="login-form mt-5 px-1">
          <Form.Group className="fc-email" controlId="formBasicEmail">
            {/* <Form.Label>Email</Form.Label> */}

            <Form.Control
              className="fc"
              type="email"
              placeholder="Email"
              onChange={(e) => onInputChange("email")(e?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <Row>
              <Col>
              <div className="passw">
            <Form.Control
               type={show ? "text" : "password"}
              placeholder="Password"
              onChange={(e) => onInputChange("password")(e?.target?.value)}
            />
            </div></Col>
            <Col>
            <div className="passweye" onClick={()=>{setShow(!show)}}>
           {show ? <img src={eye} alt="" /> :  <img src={eyeclose} alt="" /> }
           </div>
           </Col>
           </Row>
          </Form.Group>

          {error ? <div className="text-danger">{error}</div> : null}

          {/* <FormGroup className="pt-2">
            <Row>
              <Col sm={2}>
                <div className="inp-chk ">
                  <input
                    type="checkbox"
                    className="form-check-input "
                    id="rememberPassword"
                    name="checkbox"
                    // checked={rememberPassword}
                    // onChange={(event) => handleChechbox(event)}
                    // required
                  />
                </div>
              </Col>
              <Col sm={10} style={{ color: "#8A8A8A" }}>
                Remember me
              </Col>
            </Row>
          </FormGroup> */}
          <div className="pt-3">
            <Button  onClick={handleSubmit}  disabled={isBtnDisabled}>
              Sign in
            </Button>
          </div>
          <div className="pt-2 d-flex justify-content-center ">
            <Link to="/forgotpassword" style={{ textDecoration: "none", color: "blue" }}>
              <div><u>Forgot password</u></div>
            </Link>
          </div>
        </div>

        <div className="d-flex justify-content-center "></div>
      </div>
    </div>
  );
}
