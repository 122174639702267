import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import "./style.css";
import profilepic from "../../../../assets/images/Smiley.svg";


export default function Index() {
  const auth = useContext(AuthContext);
  const logo = require("../../../../assets/images/FullwhiteLogo.png");
  // const profilepic = require('../../../../assets/images/Smiley.svg')
  const navigate = useNavigate();
  const [username, setUsername] = useState()

  const handleSignOut = async () => {
    await auth.signOut();
  };

  useEffect(() => {
    //prevent logged in user from log out
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.debug("username",auth?.userAttributes?.email)
    setUsername(auth?.userAttributes?.email)
  }, []); 

  console.debug("usermail", auth?.userAttributes?.email);
  return (
    <nav className="nav shadow-nav">
      <div className="logo" onClick={() => navigate("/")}>
        <img src={logo} height="60" width="100" />
      </div>
      <div
        id="nav-ul"
        className="d-flex justify-content-center position-absolute m-3"
        style={{ right: 10 }}
      >
        <ul>Hi {username}</ul>

        <ul>
          <img src={profilepic} />
        </ul>
      </div>
    </nav>
    // <Navbar bg="dark" variant="dark">
    //   <Navbar.Brand
    //     onClick={()=> navigate('/')}
    //   >
    //     <img
    //       alt=""
    //       src="https://www.xbi4.com/static/xbi4-logo-7058dabe84088cfa094d9240fee4b58b.png"
    //       height="30"
    //       className="d-inline-block align-top"
    //     />
    //     <span className="mx-4">T-CUBE V.I.K.I</span>
    //   </Navbar.Brand>
    //   {auth?.isAuthenticated ? (
    //     <>
    //       <Navbar.Toggle />
    //       <Navbar.Collapse className="justify-content-end me-5">
    //         <Navbar.Text>
    //           <div className="btn btn-light" onClick={handleSignOut}>
    //             Signout
    //           </div>
    //         </Navbar.Text>
    //       </Navbar.Collapse>
    //     </>
    //   ) : null}
    // </Navbar>
  );
}
