import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { LayoutV1 } from "../../Layouts";

function PrivateRoute({ children }: { children: JSX.Element }) {
  const auth = useContext(AuthContext);

  if (!auth?.isAuthenticating && !auth?.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <LayoutV1>
    {children}
  </LayoutV1>
   
}

export default PrivateRoute;
