import {
  Dashboard,
  LandingPage,
  LogInPage,
  LogOut,
  SignUpPage,
  ForgotPassword,
  Setpass,
} from "../pages";
import Test from "../pages/LandingPage/test"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Project from "../pages/Dashboard/Projects/index"
import AuthRouter from "./AuthRoute";
import { LayoutV1 } from "../Layouts";
import PrivateRouter from "./PrivateRouter";
import React from "react";

export default function Index() {
  return (
    <Router>
      
        <Routes>
          <Route path="/" element={
              <AuthRouter>

          <LandingPage />
          </AuthRouter>

          } />
          <Route path="/test"element={
            <Test/>
          }/>
          <Route path="/logout" element={<LogOut />} />
          <Route
            path="/signup"
            element={
              <AuthRouter>
                <SignUpPage />
              </AuthRouter>
            }
          />
          <Route
            path="/login"
            element={
              <AuthRouter>
                <LogInPage />
              </AuthRouter>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <AuthRouter>
                <ForgotPassword />
              </AuthRouter>
            }
          />
          <Route
            path="/setpass"
            element={
              <AuthRouter>
                <Setpass />
              </AuthRouter>
            }
          />
          <Route
            path="/dashboard"
            
            element={
              <PrivateRouter>
                <Dashboard />
              </PrivateRouter>
            }
            />
          <Route path="/project" element={
            <PrivateRouter>
              <Project />
             </PrivateRouter>
          }
          />  
        </Routes>
           
    </Router>
  );
}
