import "./style.css";
import {
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Button from "../../../components/Button/index";
import { GrClose } from "react-icons/gr";
interface ValueStateProps {
  email: string;
  password: string;
}
export default function Index() {
  const navigate = useNavigate();
  const { forgotPassword} = useContext(AuthContext);

  const [values, setValues] = React.useState<ValueStateProps>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = useState<any>(null);
  const [email, setEmail] = useState("");

  const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);
  const [codeIsSent, setCodeIsSent] = React.useState(true);

  const onInputChange = (key: "email" | "password") => (value: string) => {
    setValues({ ...values, [key]: value });
  };

  const validatePassword = () => {
    return values?.password?.length >= 8;
  };



  const validateEmail = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(values?.email);
  };

  const validate = () => {
    return validateEmail() && validatePassword();
  };

  useEffect(() => {
    setIsBtnDisabled(!validate() || isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isLoading]);

  const handleRequest = async () => {
    if (email !== "") {
      await forgotPassword
        .requestCode(email)
        .then((_) => {
          console.log("OTP sent > ", _);
          setCodeIsSent(true);
          navigate("/Setpass");
        })
        .catch((err) => console.log("error > ", err));
    } else {
      setError("Email cannot be empty");
    }
  };

  // const handleSubmit = async () => {
  //   setIsLoading(true);
  //   await auth
  //     .signIn({ username: values?.email, password: values?.password })
  //     .then((e) => {
  //       setIsLoading(false);
  //       if (e?.res !== 200) {
  //         setError(e?.res?.message);
  //          navigate("/dashboard")
  //       }
  //       console.debug("logged in > ", e);
  //     })
  //     .catch((e) => {
  //       console.debug("logged in error > ", e);
  //       setError(e)
  //       setIsLoading(false);
  //     });
  // };
  return (
    <div className="container-login d-flex justify-content-center align-items-center">
      <div className="login-cube"></div>
      <div className="login-arrow"></div>
      <div className="forget-box p-3 ">
       
        <div
          className="d-flex title fs-20 f-b justify-content-center"
          style={{ color: "#8A8A8A" }}
        >
           Forgot Password
        </div>

        <div className="login-form mt-5 px-1">
          <Form.Group className="fc-email" controlId="formBasicEmail">
            {/* <Form.Label>Email</Form.Label> */}

            <Form.Control
              className="fc"
              type="email"
              placeholder="Email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
          </Form.Group>

          {error ? <div className="text-danger">{error}</div> : null}

          <div className="pt-3">
            <Button onClick={() => {
                  handleRequest();
                  // nav("/setPass");
                  // console.log("mesage on button")
                }}>
              Send OTP
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
