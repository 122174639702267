import React from "react";
import { NavBar , Sidebar } from "./components";
import circle from "../../images/Circle.svg"
import { Col, Row } from "react-bootstrap";

export default function Index({ children }) {
  return (
    <div className="d-flex">
      <NavBar />
      <Sidebar />

      <main>{children}</main>   {/* Layout Children Value    */}
    </div>
  );
}
