import React from 'react'
import "./style.css"
import Bluebg from '../../../assets/LandingPageImages/bluebg'

export default function index() {
  return (
    <>
    <div className="landing-page-container">
    <div className="main-container">
        <div className="left-container">
        <div className="blue-bg-pattern">
            <Bluebg/>  
            </div>
              <div className="left-content">
                <div>POWERFUL ANALYTICAL</div>
                <div>Platform for You</div>
                <div>Ai Powered Data Solutions</div>
                <div className="user-btns"><button>SignUp</button>
                <button>Login</button>
                </div>
              
            </div>
        </div>
        <div className="right-container">
            jhljhjf
            <img src="../../../assets/LandingPageImages/Cube-Multi-Color.svg" alt="" />
        </div>
    </div>
    </div>
    </>
  )
}


//how to write code for selectInput in r-shiny?

