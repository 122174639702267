import "./style.css";

import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";

import { AuthContext } from "../../context/AuthContext";
import Background from "../../images/blueBack.svg";
import HambergerMenu from "../../components/HamburgerMenu";

// import logo from '../../assets/LandingPageImages/Tcube-logo.png'
export default function Index() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [show, setShow] = useState(true);

  const navigateTo = () => {
    navigate("/login");
  };
  return (
    <>
      {/* <div className="rounded shadow w-50 bg-light p-3 d-flex justify-content-center align-items-center flex-column"> */}

    {/* login check */}
      {/* {auth?.isAuthenticated ? (
        <div
          className="btn btn-primary mt-2"
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </div>
      ) : ( */}

      {/* login check end */}

      
        <div className="landing-container">
          {/* <div className="landing-logo">
          </div> */}
          <div className=" landing-logo"></div>
          {/* <HambergerMenu /> */}
          <div className="landing-bottom-bar"></div>
          <div className="landing-bottombar px-2">
            <div className="landing-poweredby">
              Powered by<div className="landing-xbi4-logo"></div>
            </div>
            <div className="landing-aboutus">
              
                <a href="https://www.xbi4.com/servicesAndSolutions/tCube" target={"_blank"} style={{textDecoration: "none", color: "#8A8A8A"}}>
                  About Us
                </a>
              
            </div>
            <div className="landing-contactus">
              <a href="https://www.xbi4.com/contactUs" target={"_blank"} style={{textDecoration: "none", color: "#8A8A8A"}}>
                  Contact Us
                </a>
             
            </div>
            <div className="landing-version">Version 2.0.0</div>
          </div>
          <div className="landing-page d-flex justify-content-center align-items-start">
            <div className="cube">
              <div className="landing-page-title">
                POWERFUL ANALYTICAL
                <div className="landing-page-subtitle">
                  Platform made for you
                  <div className="landing-page-smalltext">
                    Ai Powered Data Solutions
                  </div>
                </div>
              </div>
              <div className="landing-button ">
                <div
                  className="landing-signup c-p"
                  onClick={() => navigate("/signup")}
                >
                  SignUp
                </div>

                <div
                  className="landing-login c-p"
                  onClick={() => navigate("/login")}
                >
                  Login
                </div>
              </div>
            </div>
            {/* <div
            className="btn btn-primary mt-2"
            onClick={() => navigate("/login")}
          >
            Log In
          </div> */}
            <div className="landing-background"></div>
            <div className="landing-background-color-cube"></div>
            {/* <div className="landing-bargraph"></div> */}
          </div>
        </div>
      {/* )} */}
    </>
    // </div>
  );
}
