import React, { useContext, useEffect, useMemo, useState } from "react";
import "../Projects/style.css";
// import { LayoutV1 } from "../../../Layouts";
// import { ListObjectsCommand } from "@aws-sdk/client-s3";
import { s3Client } from "../../../helpers/index";
import Data from "../../../assets/json/projects.json";
import Folder from "../../../assets/images/folder.svg";
// import ProjectNames from "../Projects/projectFolderNames"
// import React from 'react'
// Create the parameters for the bucket
import * as AWS from "aws-sdk";
import { AuthContext } from "../../../context/AuthContext";

AWS.config.update({
  accessKeyId: "AKIAYEI57SL5UWDUD3S6",
  secretAccessKey: "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",
});

// console.log("AWS IS", AWS);

function Index() {
  const auth = useContext(AuthContext);
  // console.log("USER DATA", auth?.userAttributes?.sub);
  // const [changedData, setchangedData] = useState([])

  let changedData = [];

  let project_Names = [];
  const s3 = new AWS.S3();
  const bucketName = `tcube-viki-dvv5d4-prod-prod`;
  const { project, setProject } = useContext(AuthContext);
  // const [changedDetails, setchangedDetails] = useMemo(() => return , [auth?.userAttributes?.sub])

  function getFunction() {
    s3.listObjectsV2(
      { Bucket: bucketName, Prefix: `${auth?.userAttributes?.sub}/projects/` },
      (err: AWS.AWSError, data: AWS.S3.Types.ListObjectsV2Output) => {
        if (err) {
          console.log("err in get bucket", err);
        } else {
          // console.log("DATA IS",data)
          // const folders = data.Contents.filter((obj: AWS.S3.Types.Object) => (obj.Key.split("/"))[0]=='eb121e9f-3113-4011-915e-26f914e508df');
          // const realFolders=data?.Contents.filter((obj : AWS.S3.Types.Object)=>console.log(obj))
          // console.log("REAL FOLDERS ",realFolders)
          // // console.log( "Nothing is" + folders ,folders[0] );
          // // let keyid=folders.map((data)=>data?.Key)
          // // console.log("Key Id is",keyid)
          // // fe3721e3-fb77-4130-b8a3-9df438e8fa32
          // console.log("Data is",data?.KeyCount)
          // console.log("FOLDERS",folders)

          console.log("USER DATA IS", data?.Contents);
          // console.log(data?.Contents?.LastModified.sort(function(a,b){
          //   // Turn your strings into dates, and then subtract them
          //   // to get a value that is either negative, positive, or zero.
          //   return new Date(b.date) - new Date(a.date);
          // });)
          data?.Contents?.map((data) => {
            changedData.push(data?.Key.split("/").splice(2)[0]);
          });
          // changedData=changedData.filter((data)=>data!='')
          // console.log("Changed data is", changedData);
          const uniqueName = new Set(changedData);
          // console.log(typeof project);
          setProject(uniqueName);
          // console.log("UNIQUE NAME IS", uniqueName);
          // console.log(typeof uniqueName);
        }
      }
    );
  }

  const displayFolder = () => {
    return Array.from(project).map((value) => (
      <div className="main-content-body" >
        <img src={Folder} alt="Folder-Image" />
        <text className="folder-name">{value}</text>
      </div>
    ));
  };

  useEffect(() => {
    getFunction();
    // console.log("PROJECT IS", project);
  }, []);

  return (
    <div className="project_container">
      {/* <div className="main-content-body ">
        <img src={Folder} alt="Folder-Image" />
        <text className="folder-name">Hello</text>
      </div> */}

      {displayFolder()}
    </div>
  );
}

export default Index;

// export default index
