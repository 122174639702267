import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { TCUBE_URL } from "../../const";
import { AuthContext } from "../../context/AuthContext";
import Group13 from "../../assets/images/Group13.svg";
import Rectangle123 from "../../assets/images/Rectangle123.svg";
import Rectangle124 from "../../assets/images/Rectangle124.svg";
import Rectangle125 from "../../assets/images/Rectangle125.svg";
// import Dashboardtext from "../../assets/images/Dashboardtext.png"
import "./style.css";
export default function Index() {
  // console.log("url---------", TCUBE_URL)
  const Dashboardtext = require("../../assets/images/Dashboardtext.png");
  const auth = useContext(AuthContext);
  console.debug(auth.tokens.idToken);

  return (
    <>
      <div className="container my-5">
        <div className="dashboard-image">
          {/* <img
            src={Dashboardtext}
            alt=""
            className=""
          /> */}
        </div>

        <div>
            {/* <Button
              variant="primary"
              as="a"
              href={`${TCUBE_URL}/?token=${auth.tokens.idToken.jwtToken}`}
              target="_blank"
              className="dash-button"
              >
            </Button> */}


            <div className=" dashboard-text ">
              <div className="d-flex align-content-center">

            <div className="go-to-app-btn">
              <a href={`${TCUBE_URL}?token=${auth.tokens.idToken.jwtToken}&sub=${auth?.userAttributes?.sub}`} target='_blank'>
              Go To App
              </a>
            </div>
              </div>
          </div>

          
        </div>
        <h4 style={{color: "#c4c4c4"}}>
            By Clicking on this, you will be redirected to TCUBE App which helps
            in finding the Right Target Audience for your Business Growth
          </h4>
      </div>

      {/* <div className="w-50 d-flex justify-content-end ">
  <Button
            variant="primary"
            as="a"
            href={`${TCUBE_URL}/?token=${auth.tokens.idToken.jwtToken}`}
            target="_blank"
            className="fs-3"
          >
            Go To App
          </Button>  
          </div>
          <div className="w-75 d-flex justify-content-align ">
  <h3>By Clicking on this, you will be redirecting to R shiny App which helps in finding the Right Target Audience for a Business Growth</h3></div> */}

      {/* <img src={Rectangle125} alt="" className="" height={300}  width={400}/>      */}

      {/* <div>
          <Button
            variant="primary"
            as="a"
            href={`${TCUBE_URL}/?token=${auth.tokens.idToken.jwtToken}`}
            target="_blank"
          >
            Go To App
          </Button>
        </div> */}

      {/* <div className="p-3">
        <h1>TCUBE V.I.K.I</h1>
        <div>
        <div className="p-3 d-flex justify-content-center align-items-center">
        <h1>T - CUBE V.I.K.I</h1></div>
        <div className="c-1 d-flex justify-content-center align-items-center">
          <h1> Powerful Analytical </h1></div>
          <div className="c-2 d-flex justify-content-center align-items-center">
          <h4>Platform Made for You</h4></div>
        </div>

        <div className="p-4 d-flex justify-content-center align-items-center">
          <Button
            variant="primary"
            as="a"
            href={`${TCUBE_URL}/?token=${auth.tokens.idToken.jwtToken}`}
            target="_blank"
          >
            Go To App
          </Button>
        </div>
      </div> */}
    </>
  );
}
