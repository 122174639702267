import React, { useContext } from 'react'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, Navigate } from 'react-router-dom';
import "./style.css"
import Folder from "../../../../assets/images/folderopen.svg"
import sidebar1 from "../../../../assets/images/Home.svg"
import sidebar2 from "../../../../assets/images/Contacts.svg"
import sidebar3 from "../../../../assets/images/Upload.svg"
import logOut from "../../../../assets/images/logOut.svg"
import { AuthContext } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom'


export default function Index() {

  const navigate=useNavigate() 

  const auth = useContext(AuthContext)
  return (
    <div className="side-menu">
      <div className="main-menu">
        <div className="menu-item">
          {/* <img src={sidebar2}/> */}
        </div>
        
        <div className="menu-item "  >
        <img src={sidebar1}  onClick={()=>navigate("/dashboard")}/>
        </div>        
        

        <div className="folder-open"  >
        <img src={Folder} onClick={()=>navigate("/project")}  />
        </div>
        <div className=" menu-item ">
          {/* <img src={sidebar3}/> */}
        </div>
       
        
      </div>
      <div className="bottom-menu">

      <div  className=" menu-item" onClick={()=>auth.signOut()}>
          <img src={logOut}/>
        </div>
      </div>
      {/* <div className=" d-flex m-3 py-4 sidebar-item" onClick={()=>auth.signOut()}>
          <img src={logOut}/>
        </div> */}
       

      </div>
  )
}
