import "./style.css";
import eye from "../../../assets/images/eye.svg";
import eyeclose from "../../../assets/images/eye-slash.svg";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Button from "../../../components/Button/index";
import validator from 'validator';
interface ValueStateProps {
  email: string;
  password: string;
}
export default function Index() {
  const navigate = useNavigate();
  const { forgotPassword } = useContext(AuthContext);

  const [values, setValues] = React.useState<ValueStateProps>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = useState<any>(null);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);
  const [codeIsSent, setCodeIsSent] = React.useState(true);

  // const onInputChange = (key: "email" | "password") => (value: string) => {
  //   setEmail({ ...values, [key]: value });
  // };

  // const onInputChange =
  // (key: "email" | "password" | "confirmPassword") => (value: string) => {
  //   setValues({ ...values, [key]: value });
  // };

  const validatePassword = () => {
    return values?.password?.length >= 8;
    // const passRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    // return passRegex.test(values?.email);
  };

  const validateEmail = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(values?.email);
  };

  const validate = () => {
    return validateEmail() && validatePassword();
  };

  useEffect(() => {
    setIsBtnDisabled(!validate() || isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isLoading]);

  //   const handleRequest = async () => {
  //     if (email !== "") {
  //       await forgotPassword
  //         .requestCode(email)
  //         .then((_) => {
  //           console.log("OTP sent > ", _);
  //           setCodeIsSent(true);
  //         //   navigate("/setpass");
  //         })
  //         .catch((err) => console.log("error > ", err));
  //     } else {
  //       setError("Email cannot be empty");
  //     }
  //   };

  // const handleSubmit = async () => {
  //   setIsLoading(true);
  //   await auth
  //     .signIn({ username: values?.email, password: values?.password })
  //     .then((e) => {
  //       setIsLoading(false);
  //       if (e?.res !== 200) {
  //         setError(e?.res?.message);
  //          navigate("/dashboard")
  //       }
  //       console.debug("logged in > ", e);
  //     })
  //     .catch((e) => {
  //       console.debug("logged in error > ", e);
  //       setError(e)
  //       setIsLoading(false);
  //     });
  // };

  const onSubmit = async () => {
    if (email !== "") {
      if (code !== "") {
        if (password !== "") {
          await forgotPassword
            .setPassword(email, code, password)

            .then((_) => {
              console.log("password changed > ", _);
              navigate("/login");
            })
            .catch((err) => {
              console.log("error > ", err);
            });
        } else {
          setError("Password cannot be empty");
        }
      } else {
        setError("Code cannot be empty");
      }
    } else {
      setError("Email cannot be empty");
    }
  };
  const [show, setShow] = useState(false);

  const [errorMessage, setErrorMessage] = useState('')
  
  const validater = (value) => {
  console.log("value in validater",value)
  console.log("value in validater",validator.isStrongPassword(value, {
    minLength: 8, minLowercase: 1,
    minUppercase: 1, minNumbers: 1, minSymbols: 1
  }))
    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      console.log("1st if")
      setErrorMessage(null)
    } else {
      console.log("2st if")
      setErrorMessage('Is Not Strong Password')
    }
  }

  useEffect(() => {
    if(values?.password){
  
      validater(values?.password)
    }
  }, [values?.password]);

  return (
    <div className="container-login d-flex justify-content-center align-items-center">
      <div className="login-cube"></div>
      <div className="login-arrow"></div>
      <div className="forget-setpass p-4 ">
        <div
          className="d-flex title fs-20 f-b justify-content-center"
          style={{ color: "#8A8A8A" }}
        >
          Set Password
        </div>
        <div className="login-form mt-3 ">
          <Form.Group className="fc-email" controlId="formBasicEmail">
            {/* <Form.Label>Email</Form.Label> */}

            <Form.Control
              className="fc"
              type="email"
              placeholder="Email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="fc-email mt-3" controlId="formBasicEmail">
            <Form.Control
              className="fc"
              type="code"
              placeholder="Code"
              id="code"
              value={code}
              onChange={(e) => setCode(e?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="fc-email mt-3" controlId="formBasicPassword">
            <Row>
              <Col>
                <div className="passw">
                  <Form.Control
                    className="fc"
                    type={show ? "text" : "password"}
                    placeholder="eg: Q!w2e3r4"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e?.target?.value)}
                  />
                </div>
              </Col>
              <Col>
                <div
                  className="passweye"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  {show ? (
                    <img src={eye} alt="" />
                  ) : (
                    <img src={eyeclose} alt="" />
                  )}
                </div>
              </Col>
            </Row>
          </Form.Group>

          {error ? <div className="text-danger">{error}</div> : null}
          {errorMessage ? <div className="text-danger">{errorMessage}</div> : null}

          <Form.Group className="pt-2">
            <div style={{ color: "red", fontSize: "12px" }}>
            <p>* At least one upper case English letter,<br/>
    * At least one lower case English letter,<br/>
    * At least one digit,<br/>
    * At least one special character,<br/>
    * Minimum eight in length.</p>
            </div>
          </Form.Group>

          <div className="mt-2 reset-button">
            <Button
              onClick={() => {
                // history.push("/setPass");
                onSubmit();
                console.debug("mesage on button");
              }}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
