import "./style.css";

import React, { Component } from "react";

import { Button } from "react-bootstrap";

interface Props {
  title?: string;
  onClick?: any;
  color?: string;
  className?: string;
  children?: string | Component;
  disabled?: boolean;
}
function Index({
  title,
  onClick,
  color,
  children,
  className,
  disabled,
}: Props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
      className="mt-2 "
    >
      <Button
        onClick={onClick}
        disabled={disabled}
        className="auth-button"
      >
        {children || title}
      </Button>
    </div>
  );
}

export default Index;
