import { createContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser, ISignUpResult } from "amazon-cognito-identity-js";

interface signInProps {
  username: string;
  password?: string;
}
interface AuthProviderProps {
  children: any;
}

type IdTokenType = {
  jwtToken: string;
  payload: any;
};
interface tokenProps {
  idToken: IdTokenType;
}

interface signUpProps {
  username: string;
  password: string;
}

type signUpFunctionType = (props: signUpProps) => Promise<
  | { statusCode: number; res: ISignUpResult }
  | {
      statusCode: number;
      err: any;
    }
>;

interface AuthContextInterface {
  signIn: (props: signInProps) => Promise<
    | {
        statusCode: number;
        res: any;
      }
    | {
        statusCode: number;
        res: any;
      }
  >;
  isAuthenticated: boolean;
  user: CognitoUser | null | any;
  userAttributes: any | null;
  isAuthenticating: boolean;
  signOut: Function;
  signUp: signUpFunctionType;
  userInfo: any;
  tokens: tokenProps;
  forgotPassword: any;
  setProject: any[] | any;
  project:any[] | any;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userInfo, setUserInfo] = useState(null);
  const [project, setProject] = useState([]);

  const [userAttributes, setUserAttributes] = useState<any | null>(null);
  const [tokens, setTokens] = useState<tokenProps>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const handleToken = (key: "idToken", value: any) => {
    setTokens({ ...tokens, [key]: value });
  };

  // ? prevent user form signout
  async function fetchAuthenticatedUser() {
    try {
      const session = await Auth.currentSession();
      handleToken("idToken", session?.getIdToken());
      console.debug("session", session);
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      setUser(authenticatedUser);
      // console.debug("fetch user > ", authenticatedUser);
      setIsAuthenticated(true);

      setUserAttributes(authenticatedUser?.attributes);
      console.debug("user attr > ", authenticatedUser?.attributes);
      return authenticatedUser;
    } catch (error) {
      setIsAuthenticated(false);
      console.debug("fetchAuthenticatedUser() >", error);
    }
  }
  useEffect(() => {
    //prevent logged in user from log out
    fetchAuthenticatedUser().then((_) => setIsAuthenticating(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //? sign in function
  const signIn = async ({ username, password }: signInProps) => {
    return await Auth.signIn(username, password)
      .then((res:any) => {
        setUser(res);
        setUserAttributes(res?.attributes)
        setIsAuthenticated(true);

        handleToken("idToken", res?.signInUserSession?.idToken);
        console.debug(res);
        return { statusCode: 200, res };
      })
      .catch((err) => {
        console.debug("error signing in", err?.message);
        //  return { statusCode: 400, res };
        throw err?.message;
      });
  };

  const signUp = async ({ username, password }: signUpProps) => {
    // ! username is mail id
    const params = {
      username: username,
      password: password
    };
    return await Auth.signUp(params)
      .then((res:any) => {
        console.log(res?.message)
        console.debug("signup >gthrt ", res);
        return { statusCode: 200, res };
      })
      .catch((err) => {
        console.debug("signUp  > ", err?.message);
        // alert( err?.message);
        throw err?.message;
      });
  };

  const forgotPassword = {
    requestCode: async (email) => {
      // setIsLoading(true);
      const res = await Auth.forgotPassword(email)
        .then((_) => {
          console.log(_);
        })
        .catch((err) => console.log("err", err));
      // setIsLoading(false);
      console.log("code sent", email);
      return res;
    },
    setPassword: async (email, code, password) => {
      // setIsLoading(true);
      const res = await Auth.forgotPasswordSubmit(email, code, password)
        .then((_) => {
          console.log(_);
        })
        .catch((err) => console.log("err", err));
      // setIsLoading(false);
      console.log(email, code, password);
      return res;
    },
  };

  const signOut = async () => {
    await Auth.signOut()
      .then((res) => {
        console.debug("signout res", res);
        setIsAuthenticated(false);
        setUser(null);
        setUserAttributes(null);
      })
      .catch((err) => {
        console.debug("err in signout", err);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        isAuthenticated,
        signIn,
        user,
        userAttributes,
        isAuthenticating,
        signOut,
        signUp,
        forgotPassword,
        tokens,
        setProject,
        project
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
