import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import Amplify from "aws-amplify";
import { awsRegion, cognitoClientId, cognitoPoolId } from "./const";

Amplify.configure({
  Auth: {
    region: awsRegion,
    userPoolId: cognitoPoolId,
    userPoolWebClientId: cognitoClientId
  }
});

if (process.env.NODE_ENV === "production") {
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
