import React from 'react'

const bluebg = () => {
  return (
    <>
    <svg width="1000" height="859" viewBox="0 0 1000 859" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_3_5)">
<path d="M441.98 0H0V753.51C20.9168 795.372 50.9278 814.483 72.754 829.043C140.961 863.625 225.537 847.244 238.269 846.334C276.101 842.694 323.755 819.943 342.853 809.022L415.607 761.7L503.821 682.527C506.853 678.887 520.736 663.417 552.021 630.655C601.13 581.513 612.952 590.614 620.228 592.434C642.963 603.354 623.865 633.385 622.046 643.396C614.936 682.527 668.427 670.697 677.521 666.147C688.434 661.05 702.553 653.549 719.355 631.565L765.735 561.492L815.754 467.759C826.667 447.435 851.039 402.418 861.225 384.945C875.776 359.464 898.511 337.624 913.972 327.613C925.612 321.061 923.672 331.557 921.247 337.624C914.578 358.858 901.865 389.643 901.24 404.966C899.785 440.64 915.79 438.031 923.975 432.267L937.617 420.437L981.269 369.475C989.272 362.194 989.454 368.261 988.544 372.205L983.997 384.945L988.544 384.035C989.454 382.215 991.818 377.301 994.001 372.205C1000.55 349.636 989.454 353.094 983.088 357.644C985.21 356.128 971.148 365.261 969.447 365.835C967.745 366.409 927.613 385.855 942.164 340.354C983.997 247.53 930.046 273.946 909.424 287.572C842.127 349.454 869.41 289.392 882.142 254.81C909.424 154.706 774.83 231.149 736.634 254.81C612.952 318.513 612.952 234.789 578.394 131.045C544.927 27.6651 473.507 0.606691 441.98 0Z" fill="#4B54E5"/>
</g>
<defs>
<filter id="filter0_d_3_5" x="-4" y="0" width="1004" height="859" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_5"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_5" result="shape"/>
</filter>
</defs>
</svg>
    </>
  )
}

export default bluebg