import React, {useContext, useEffect} from 'react'
import {AuthContext} from '../../../context/AuthContext'
import {useNavigate} from 'react-router-dom'

export default function Index(){
    const auth = useContext(AuthContext)
    const navigate = useNavigate()

    const handleSignout = async()=>{
        await auth.signOut().then(()=>navigate('/'))
    }

    useEffect(() => {
      handleSignout()
    }, [])
    
    return <></>
}