import "./style.css";
import validator from 'validator';
import eye from "../../../assets/images/eye.svg"
import eyeclose from "../../../assets/images/eye-slash.svg"
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../context/AuthContext";
import Button from "../../../components/Button/index";
import { ClassificationTypeNames } from "typescript";
import { GrClose } from "react-icons/gr";


interface ValueStateProps {
  email: string;
  password: string;
  confirmPassword: string;
}

export default function Index() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [values, setValues] = React.useState<ValueStateProps>();

  const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = useState<any>("");
  const onInputChange =
    (key: "email" | "password" | "confirmPassword") => (value: string) => {
      setValues({ ...values, [key]: value });
    };

  const validatePassword = () => {
    return values?.password?.length >= 8;
  };

  const validateEmail = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(values?.email);
  };

  const validate = () => {
    return (
      validateEmail() &&
      validatePassword() &&
       values?.confirmPassword === values?.password
    );
  };
  

  useEffect(() => {
    setIsBtnDisabled(!validate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    console.debug("submit > ", values);
    await auth
    ?.signUp({ username: values?.email, password: values?.password })
    .then((e) => {
        // if (e?.res !== 200) {
        //   setError(e?.res?.message);
        // }
        console.log("eeee",e)
         navigate("/login");
        
        setIsLoading(false);
      })
      .catch((e) => {
        console.debug("error while sign up > ", e);
        setError(e);
        setIsLoading(false);
      });
  };

  const [errorMessage, setErrorMessage] = useState('')
  
  const validater = (value) => {
  console.log("value in validater",value)
  console.log("value in validater",validator.isStrongPassword(value, {
    minLength: 8, minLowercase: 1,
    minUppercase: 1, minNumbers: 1, minSymbols: 1
  }))
    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      console.log("1st if")
      setErrorMessage(null)
    } else {
      console.log("2st if")
      setErrorMessage('Is Not Strong Password')
    }
    // if(values?.confirmPassword != values?.password
    //   ){
    //     setErrorMessage("Password and Re-enter Password are not same")
    //   }
  }


//   const [pwdstr, setPwdstr] = useState(false)
  const [show, setShow] = useState(false)
  const [showre, setShowre] = useState(false)
useEffect(() => {
  if(values?.password){

    validater(values?.password)
  }
}, [values?.password]);

useEffect(() => {
  if(values?.confirmPassword != values?.password
      ){
        setErrorMessage("Password and Re Enter Password are not same")
      }
    else{
      setErrorMessage("")
    }
}, [values?.confirmPassword]);

  return (
   
    <div className="container-signup d-flex justify-content-center align-items-center">
      <div className="signup-cube"></div>
      <div className="signup-arrow"></div>
      <div className="signup-container p-3 ">

      <div className="home" style={{width:"10px"}}>
                <Link to="/">
                    <GrClose/>
                   </Link>
            
      </div>
       
          {/* <div 
            onClick={() => navigate("/")}
            className="home" style={{width:"10px"}}
            >
              <button style={{ color: "#8A8A8A" }}>
               X
              
              </button>
              </div> */}
        <div
          className="d-flex title fs-20 f-b justify-content-center"
          style={{ color: "#8A8A8A" }}
        >
          Sign up
        </div>
        

        <div className="signup-form mt-3 px-1">
          <Form.Group className="fc-email" controlId="formBasicEmail">
            {/* <Form.Label>Email</Form.Label> */}

            <Form.Control
              className="fc"
              type="email"
              placeholder="Email"
              onChange={(e) => onInputChange("email")(e?.target?.value)}
            />
          </Form.Group>


          <Form.Group className="mt-3" controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <Row>
              <Col>
              <div className="passw">
            <Form.Control
              type={show ? "text" : "password"}
              placeholder="Password"
              onChange={(e) => {onInputChange("password")(e?.target?.value);
              // onChange={(e) => validater(e?.target?.value)}
            }}
            />
            </div></Col>
            <Col>
            <div className="passweye" onClick={()=>{setShow(!show)}}>
           {show ? <img src={eye} alt="" /> :  <img src={eyeclose} alt="" /> }
           </div>
           </Col>
           </Row>
          </Form.Group>


          <Form.Group className="mt-3" controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <Row>
              <Col>
              <div className="passw">
            <Form.Control
               type={showre ? "text" : "password"}
              placeholder="Re Enter Password"
              onChange={(e) =>
                onInputChange("confirmPassword")(e?.target?.value)
              }
            />
            </div></Col>
            <Col>
            <div className="passweye" onClick={()=>{setShowre(!showre)}}>
           {showre ? <img src={eye} alt="" /> :  <img src={eyeclose} alt="" /> }
           </div>
           </Col>
           </Row>
          </Form.Group>

          {error ? <div className="text-danger">{error}</div> : null}
          {errorMessage ? <div className="text-danger">{errorMessage}</div> : null}
         
          <FormGroup className="pt-2">
            <div style={{ color: "#8A8A8A", fontSize: "12px" }}>
              By Signing up you agree to our Terms and Conditions
            </div>
          </FormGroup>
          <div className="pt-2">
            <Button disabled={isBtnDisabled} onClick={(e)=>{e.preventDefault();handleSubmit(e)}}>
              Sign Up</Button>

            <div
          
          className=" text-muted d-flex justify-content-center "
        >
          Already a member ?
          <Link
            to="/login"
            style={{ textDecoration: "none", color: "#4B54E5" }}
          >
           Login
          </Link>
        </div>
        </div>

       
      </div>
    </div>
    </div>
  );
}
