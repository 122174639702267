import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function PrivateRoute({ children }: { children: JSX.Element }) {
  const auth = useContext(AuthContext);

  if (auth?.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return children;
}

export default PrivateRoute;
