import * as React from "react";
import Router from "./Router";
import { Helmet } from "react-helmet";
import { AuthProvider } from "./context/AuthContext";
import AWS from 'aws-sdk';
// import awsmobile from './aws-exports';


  
// AWS.config.update({
//   region: awsmobile.aws_cognito_region,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: awsmobile.aws_cognito_identity_pool_id
//   })
// });
function App() {
  
  return (
    <>
      <AuthProvider>
        <Helmet>
          <title>TCUBE V.I.K.I</title>
        </Helmet>
        <Router />
      </AuthProvider>
    </>
  );
}

export default App;
